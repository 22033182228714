<template>
  <div>
    <p>
      
    </p>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Learn how we help your organization to become more productive!</h1>
      <p>
        Did you know that offering a corporate wellness program can, at the same time, significantly improve productivity and reduce healthcare costs?!
        Fitter employees have less stress and feel more at ease at their workplace. 
      </p>
      <p>
        Offering a connected fitness challenge is a great way to organize a corporate wellness program which is both fun and engaging at the same time. 
        By allowing your employees to use any fitness app or device they might already have you make it easy for them to join your program while keeping the tracker they already use.
      </p>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" class="text-center align-center pa-4" to="/get">
            <v-icon size="50" color="grey">fa-mobile</v-icon>
            <h3 class="my-4">iPhone and Android App</h3>
            <p>Download our free iPhone or Android app to track your progress.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" class="text-center align-center pa-4" to="/integrations/fitness">
            <v-icon size="50" color="grey">fa-watch-fitness</v-icon>
            <h3 class="my-4">9+ Fitness Device Integrations</h3>
            <p>Connect any of our many supported devices for auto-sync from your app or watch.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" class="text-center align-center pa-4">
            <v-icon size="50" color="grey">fa-keyboard</v-icon>
            <h3 class="my-4">Manual Entry</h3>
            <p>Easily enter activities and steps on our website.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" class="text-center align-center pa-4" to="/integrations/microsoft-teams">
            <img height="50px" src="https://sodisp.imgix.net/web/microsoft-teams-logo-sm.png?h=50" class="mb-n2" style="filter:grayscale(1);"/>
            <h3 class="my-4">Microsoft Teams</h3>
            <p>Integrate into Microsoft Teams channels and chats for a seamless connected Teams experience.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" class="text-center align-center pa-4" to="/integrations/slack">
            <v-icon size="50" color="grey">fab fa-slack</v-icon>
            <h3 class="my-4">Slack Integration</h3>
            <p>Integrate into your Slack workspace for a seamless and integrated experience for your team.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" class="text-center align-center pa-4" to="/solutions/fitness-engine">
            <v-icon size="50" color="grey">fa-cogs</v-icon>
            <h3 class="my-4">APIs and custom apps</h3>
            <p>Easily embed our proven technology stack in a custom app or platform matching your brand experience.</p>
          </v-card>
        </v-col>
      </v-row>
      <h3>Device agnostic and inclusive</h3>
      <p>
        We offer a device agnostic platform which support almost all popular fitness trackers currently on the market. 
        Our platform allows you to host inclusive challenges which are motivating all of your workforce, not only the most competitive few!
      </p>
      <p class="mb-0">Some of the fun challenges we've hosted are</p>
      <ul class="mb-4">
        <li>Monthly streak challenges: be active as many days in a month (any sport or activity may count)</li>
        <li>Total time challenge: how many hours are you active in a month?</li>
        <li>Team distance challenges: which team/department can complete a certain distance the fastest?</li>
        <li>... and many more!</li>
      </ul>
      <h3>All you need to get started, today!</h3>
      <p>
        Explore the features {{tenant.name}} has to offer to make host your connected fitness event and get more happy employees.
      </p>
      <div class="text-center">
        <img class="elevation-12 " style="max-width:100%;" src="https://sodisp.imgix.net/content/running-shoes-stairs.jpg?w=1080&amp;h=400&amp;fit=fillmax" alt="Create engaging and connected corporate fitness events and challenges." /> 
      </div>
    </div>
    <div id="page-customers" class="mt-0 blob-bg orange white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by global corporations</h1>
            <p>
              {{tenant.name}} is used by world-renowned corporations to host their connected fitness events. Some of our key partners are:
            </p>
            <SectionCustomers :showcase="false" corporate/>

          </v-col>
        </v-row>
      </div>
    </div>
    <div>
      <h2 class="subheader mt-8">Integration with all major fitness trackers</h2>
      <p>
        We offer native integration with all major fitness wearables for automatic upload of activities. This greatly improves the experience of your participants! <br/>
        Our manual entry tool allows quick entry for everyone without a connected GPS device.
      </p>
      <SectionIntegrations />
    </div>

    <div>
      <h2 class="subheader mt-8">Native iOS and Android app</h2>
      <p>
        Download our app for iPhone and Android to track your activities and see the challenge progress in real-time!
      </p>
      <p>
        <v-btn text color="primary" to="/get">Read more</v-btn>
      </p>
      <img class="elevation-12 " style="max-width:100%;" src="https://sodisp.imgix.net/web/app/cofi-app-devices.png?w=1080" alt="Get our app." /> 
    </div>

    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="subtitle">Download our app!</h1>
        </v-col>
        <v-col cols="6" style="display:flex;flex-direction:column;align-items:flex-end;">
          <a class="mr-2" href='https://apps.apple.com/app/connected-corporate-fitness/id1594403776' target="_blank"><img style="max-width:100%;" alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png'/></a>
        </v-col>
        <v-col cols="6">
          <a href='https://play.google.com/store/apps/details?id=app.corporatefitness' target="_blank"><img style="max-width:215px; margin: -15px;" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="text-center">
      <img class="elevation-12 " style="max-width:100%;" src="https://sodisp.imgix.net/content/fitness-phone.jpg?w=1080&amp;h=500&amp;fit=fillmax" alt="Create engaging and connected corporate fitness events and challenges." /> 
    </v-container>

    <div class="secondary white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Get more healthy employees</h2>
            <h1 class="subtitle">Create engaging and beautiful experiences!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/request-demo">Request Demo</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <div :class="`mt-10 text-center ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Discover our key features</h1>
      <p>
      </p>
    </div>
    <v-container class="features">
      <v-row>
                <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-watch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fab fa-strava</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fab fa-apple</v-icon>
          <h4>Auto-sync with Garmin, Strava, Fitbit, Apple Watch and more</h4>
          <p>
            Activities tracked with Garmin, Apple Watch, Fitbit, or Polar devices and the Strava or MapMyRun apps are automatically synced the minute they are saved. Make each breath and every km/mile count!
          </p>
          <p>Don't have a fitness device or app? No worries, we've got you covered as well. Easily add your activities with only a few clicks using our manual entry tool.</p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-stopwatch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-ruler</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-mountain</v-icon>
          <h4>Any goal, any challenge!</h4>
          <p>
            We support all challenge modes you can think of, plus more! You do running? Check! Yoga? Sure!
          </p>
          <p> 
            Whether your fitness event runs a week, a month, or longer, whether it is a single sport or a multi-sport event, whether it is competitive or inclusive to all, you can host it on {{tenant.name}}.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-map</v-icon>
          <h4>Progress on map</h4>
          <p>
            Make your challenge more fun by mapping the progress on a real map. Simply upload your course map and we'll map the progress on the map!
          </p>
          <p>
            Fun ideas: do a virtual tour around various office locations or draw your logo on the map.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-shield</v-icon>
          <h4>Badges</h4>
          <p>
            Create your own intermediate goals to keep your employees motivated to make it to the (virtual) finish line! 
          </p>
          <p>
            We have plenty different badges available to keep everybody challenged in reaching and improving their fitness.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-bullseye</v-icon>
          <h4>Be inclusive: Collective progress &amp; Participation Mode</h4>
          <p>
            Let's work together in reaching our common goals!
            Easily set a collective target and everyone's effort will count toward reaching this collective goal!
          </p>
          <p> 
            Enable our participation mode for non-competitive challenges to change the leaderboard into a ticker board where the latest activity 
            is shown at the top to give everyone their moment in the spotlights, regardless of distance, pace, or speed!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-user</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-users</v-icon>
          <h4>Individual or teams</h4>
          <p>
            What's even more fun than reaching your personal fitness goal? Doing it together with your team or department!
          </p>
          <p>
            {{ tenant.name }} supports advanced team challenges to allow teams and departments to workout together and compete with the other teams and departments.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-paint-roller</v-icon>
          <h4>Customize design &amp; co-branding</h4>
          <p> 
            Make each event truly yours by customizing the design to match your brand. Upload your logo and artwork to make your event look beautiful.
          </p>
          <p>
            Want to take it a step further? We offer a completely co-branded, white-labeled version of the platform which you can host on your own domain.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-cogs</v-icon>
          <h4>Single Sign-On (SSO) and API integrations</h4>
          <p> 
            Connect our platform with your enterprise identity platform using SSO integration to allow your employees to connect using their corporate account.
            Use our REST APIs for custom integration with your systems and services. We provide APIs for various integration scenarios such as automated result or registration data sync. 
            We support iframe embedding for a no-code integration.
            We have Excel import/export options available to help you get started without development work needed.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tools</v-icon>
          <h4>Powerful Management Tools</h4>
          <p> 
            Use our easy to use, yet powerful management tools to manage your virtual challenge and results. All features can be configured using our self-service tools. Need help? No worries, we have extensive knowledge base articles available or can help you with an onboarding trainings session.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tags</v-icon>
          <h4>Pricing that scales with your organization</h4>
          <p> 
            Get started with for free and pay only for what you use. See our <router-link to="/pricing">pricing</router-link> for details.
          </p>
        </v-col>
      </v-row>
    </v-container>


    <v-container class="text-center">
      <img class="elevation-12 " style="max-width:100%;" src="https://sodisp.imgix.net/content/runner-stretch.jpg?w=1080&amp;h=500&amp;fit=fillmax" alt="Create engaging and connected corporate fitness events and challenges." /> 
    </v-container>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>


  </div>

</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>